import * as Sentry from "@sentry/vue";

const ENABLE_TRACK = !import.meta.dev;

export default class ErrorMonitoring {
  //static setUser(profile: UserProfile) {
  //  Sentry.setUser({
  //    id: profile.id,
  //    name: profile.Name,
  //    email: profile.Email,
  //    username: profile.Username,
  //  });

  //  Sentry.setTag("company", profile["Company ID"]);
  //}

  static captureException(e: any) {
    if (ENABLE_TRACK) {
      Sentry.captureException(e);
    } else {
      console.error(e);
    }
  }

  static captureMessage(e: string) {
    if (ENABLE_TRACK) {
      Sentry.captureMessage(e);
    } else {
      console.error(e);
    }
  }
}
