<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import * as auth from "~/services/auth";
import ErrorMonitoring from "~/services/error_monitoring";

export type Org = {
  id: number;
  name: string;
  img?: string;
  slug: string;
};

interface Props {
  disabled?: boolean;
}

defineProps<Props>();

async function logout() {
  try {
    await auth.logout();

    await navigateTo({ name: "login" }, { replace: true });
  } catch (e) {
    ErrorMonitoring.captureException(e);
  }
}
</script>

<template>
  <Popover
    as="div"
    v-slot="{ open }"
    class="flex flex-row relative items-center"
  >
    <PopoverButton class="focus-visible:outline-none" :disabled="disabled">
      <slot name="trigger" :open="open" />
    </PopoverButton>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <PopoverPanel
        class="absolute py-1 right-0 top-11 origin-top-right flex flex-col gap-1 shadow-lg w-max bg-[--background] z-20 rounded py-1 border rounded border-secondary-border min-w-52"
      >
        <div
          class="py-2 h-fit hover:cursor-pointer hover:bg-secondary-active px-4 text-sm"
          @click="logout"
        >
          Sair
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
